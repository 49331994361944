import React from "react";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import {Stage, Layer, Transformer} from "react-konva";
import Image from "./Image";
import '../../CSS/FormGrammage.css';
import * as Konva from "konva";

const uuid = require("uuid");

function HomePage(props) {
    const [images, setImages] = React.useState([]);
    const [selectedId, selectShape] = React.useState(null);
    const [shapes, setShapes] = React.useState([]);
    const [, updateState] = React.useState();
    const fileUploadEl = React.createRef();
    const handlClose = props.handleClose;
    const drawImage = () => {
        updatePreview()
        fileUploadEl.current.click();
        updatePreview()
    };
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const fileChange = (ev) => {
        let file = ev.target.files[0];
        let reader = new FileReader();
        updatePreview()
        reader.addEventListener("load", () => {
            const id = uuid.v4();
            images.push({
                content: reader.result, id
            });
            setImages(images);
            fileUploadEl.current.value = null;
            shapes.push(id);
            setShapes(shapes);
            forceUpdate();
            updatePreview()
        }, false);
        if (file) {
            reader.readAsDataURL(file);
        }
        updatePreview()
    };

    const stageRef = React.useRef(null)

    document.addEventListener("keydown", (ev) => {
        if (ev.code === "Delete") {
            let index = images.findIndex((r) => r.id === selectedId);
            if (index !== -1) {
                images.splice(index, 1);
                setImages(images);
                updatePreview()
            }
            updatePreview();
            forceUpdate();

        }
    });

    const handleExport = () => {
        const uri = stageRef.current.toDataURL();
        downloadURI(uri, 'stage.png');
    };



    function downloadURI(uri, name) {
        var link = document.createElement('a');
        link.download = name;
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    function updatePreview() {
        const scale = 1 / 4;
        // use pixelRatio to generate smaller preview
        const urlr = stageRef.current.toDataURL({pixelRatio: scale});
        console.log("log urlr")
        console.log(urlr)
        document.getElementById('preview').src = urlr;
    }

    return (<div className="home-page" id={"homePage"} style={{width: 50+ "%", height: 50 + "%"}}
    >

        <div id="button_editor">
            <ButtonGroup>
                <Button id="btn-importation-image" variant="secondary" onClick={drawImage}>
                    Importer image (JPEG,PNG...)
                </Button>
                <button id="btn-download-visual" variant="secondary" onClick={handleExport}>Télécharger le visuel
                </button>
                <button id="btn-close-modal" variant="secondary" onClick={handlClose}>Fermer l'éditeur</button>
            </ButtonGroup>
            <input
                style={{display: "none"}}
                type="file"
                ref={fileUploadEl}
                onChange={fileChange}
            />
        </div>

        <div id="PageEditor">
            <img id="preview" border="double"/>

            <Stage
                width={props.width}
                height={props.heigth}
                ref={stageRef}
                border="double"

            >
                <Layer>
                    {images.map((image, i) => {
                        return (<><Image
                            key={i}
                            enabledAnchors={['top-left', 'top-right', 'bottom-left', 'bottom-right']}
                            imageUrl={image.content}
                            isSelected={image.id === selectedId}
                            onSelect={() => {
                                selectShape(image.id);
                                updatePreview()
                            }}
                            onChange={(newAttrs) => {
                                const imgs = images.slice();
                                imgs[i] = newAttrs;
                                updatePreview()
                            }}
                        />
                        {/*<Transformer  key={i} />*/}
                            </>
                        );
                    })}
                </Layer>
            </Stage>

        </div>
    </div>);
}

export default HomePage;
