import React from 'react';
import {Form, Container, Alert, Button, ListGroup, Row, Col, Badge, ButtonGroup, DropdownButton} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../CSS/FormGrammage.css';
import RangeSlider from 'react-bootstrap-range-slider';
import Canvas from "../canvas";
import Canvasprint from "../canvasPrint";
import backImgA from "../img/ETIQUETTES CO2 A.png";
import backImgB from "../img/ETIQUETTES CO2 B.png";
import backImgC from "../img/ETIQUETTES CO2 C.png";
import backImgD from "../img/ETIQUETTES CO2 D.png";
import backImgE from "../img/ETIQUETTES CO2 E.png";
import backImgF from "../img/ETIQUETTES CO2 F.png";
import backImgG from "../img/ETIQUETTES CO2 G.png";
import fleche_header_top from "../img/fleche-header-co2.png";
import fleche_header_bottom from "../img/fleche2-header-co2.png";
import logo_bpm from "../img/BPM-Group-logo.png";
import HomePage from "../Canvas_editor_image/HomePage";
import Modal from "react-modal";
import {createBrowserHistory as createHistory} from "history";


class GrammageForm extends React.Component {

    constructor(props) {
        super(props);
        this.GramInput = React.createRef();
        this.state = {
            dimReal: 0,
            Gram: "",
            GramSlider: "",
            letter: "",
            showOtherDimension: false,
            activeWeb: false,
            activePrint: false,
            DimmensionL: "0",
            DimmensionH: "0",
            ResultCO2: "",
            test: "",
            stateDimension: false,
            stateGrammage: false,
            statePrevisualize: false,
            showEditor: false,
            showStore: false,
            etiquette: backImgA,
            x: 120,
            ratioHeigth: 0.138,
            read: false,
            modalIsOpen: false,
            customStyles: "",
            defaultFormat:false,
            history: createHistory(),

        };
        this.handleBlur = this.handleBlur.bind(this);
        this.handleDoubleClick= this.handleDoubleClick.bind(this);
        this.handleSelectDefaultFormat = this.handleSelectDefaultFormat.bind(this);
        this.handleStat = this.handleStat.bind(this);
        this.handleClickWebandPrint = this.handleClickWebandPrint.bind(this);
        this.cancelForm = this.cancelForm.bind(this);
        this.handleVisu = this.handleVisu.bind(this);
        this.handleEditor = this.handleEditor.bind(this);
        this.toggleTicketModal = this.toggleTicketModal.bind(this);
        this.textInput = React.createRef();
        this.textInputDim = React.createRef();
        this.focus = this.focus.bind(this);
        this.focusDim = this.focusDim.bind(this);
    }



    cancelForm(event) {
        this.setState({
            dimReal: 0,
            Gram: "",
            GramSlider: "",
            GramValue: 0,
            letter: "",
            showOtherDimension: false,
            activeWeb: true,
            activePrint: false,
            DimmensionL: "0",
            DimmensionH: "0",
            ResultCO2: "",
            test: "",
            stateDimension: false,
            stateGrammage: false,
            statePrevisualize: false,
            showStore: false,
            showEditor: false,
            etiquette: backImgA,
            x: 120,
            ratioHeigth: 0.138,
            read: false,
            modalIsOpen: false,
            customStyles: "",
            defaultFormat:false,
            history: createHistory(),
        });
    }

    toggleTicketModal(event) {
        this.setState({modalIsOpen: !this.state.modalIsOpen,});

    }

    handleSelectDefaultFormat(event) {
        let ValueH = 0;
        let ValueL = 0;
        switch (event.target.value) {
            case 'instgramCarre':
                ValueH = 1080;
                ValueL = 1080;
                break;
            case 'instgramVertical':
                ValueH = 1350;
                ValueL = 1080;
                break;
            case 'A3':
                ValueH = 297;
                ValueL = 420;
                break;
            case 'A4':
                ValueH = 297;
                ValueL = 210;
                break;
            case 'A5':
                ValueH = 210;
                ValueL = 148;
                break;
            default:
                ValueH = 1920;
                ValueL = 1080;
                break;
        }
        this.setState({DimmensionL: ValueL, DimmensionH: ValueH, stateGrammage: true,defaultFormat:true})
        this.focus()

    }

    handleVisu(event) {

        let cadreL, cadreH;
        cadreH = parseInt(this.state.DimmensionH) + 45;
        cadreL = parseInt(this.state.DimmensionL) + 30;
        this.setState({
            showStore: true, read: true, customStyles: {
                content: {
                    top: '10%',
                    left: '40%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    width: 100 + '%',
                    height: 100 + '%',
                    overflow: 'scroll',
                    transform: 'translate(-40%, -10%)',
                },
            }
        });
    }

    handleEditor(event) {
        this.setState({showEditor: true}, () => {
        });

    }
    focus() {
        // Explicitly focus the text input using the raw DOM API
        // Note: we're accessing "current" to get the DOM node
        this.textInput.current.focus();
    }
    focusDim() {
        // Explicitly focus the text input using the raw DOM API
        // Note: we're accessing "current" to get the DOM node
        this.textInputDim.current.focus();
    }

    handleStat(event) {
        const valueGr = event.target.value;
        const dimConvertWidth = this.state.activeWeb ? this.state.DimmensionL / 18 : (this.state.DimmensionL * 3.78) / 18
        const dimConvertHeight = this.state.activeWeb ? this.state.DimmensionH / 18 : (this.state.DimmensionH * 3.78) / 18

        this.setState({dimReal: dimConvertWidth + dimConvertHeight})
        this.setState({GramValue: event.target.value}, () => {
            if (valueGr >= 101 && valueGr <= 120) {

                this.setState({
                    ResultCO2: ('Classe B :  ' + valueGr + ' g/CO2/km'), etiquette: backImgB, x: 85, ratioHeigth: 0.135
                });
            } else if (valueGr >= 121 && valueGr <= 140) {

                this.setState({
                    ResultCO2: ('Classe C : ' + valueGr + ' g/CO2/km'),
                    etiquette: backImgC,
                    x: 125,
                    ratioHeigth: 0.135 * 2
                });
            } else if (valueGr >= 141 && valueGr <= 160) {

                this.setState({
                    ResultCO2: ('Classe D : ' + valueGr + ' g/CO2/km'),
                    etiquette: backImgD,
                    x: 165,
                    ratioHeigth: 0.135 * 3
                });
            } else if (valueGr >= 161 && valueGr <= 200) {

                this.setState({
                    ResultCO2: ('Classe E : ' + valueGr + ' g/CO2/km'),
                    etiquette: backImgE,
                    x: 205,
                    ratioHeigth: 0.135 * 4
                });
            } else if (valueGr >= 201 && valueGr <= 250) {

                this.setState({
                    ResultCO2: ('Classe F : ' + valueGr + ' g/CO2/km'),
                    etiquette: backImgF,
                    x: 245,
                    ratioHeigth: 0.135 * 5
                });
            } else if (valueGr > 250) {

                this.setState({
                    ResultCO2: ('Classe G : ' + valueGr + ' g/CO2/km'),
                    etiquette: backImgG,
                    x: 285,
                    ratioHeigth: 0.135 * 6

                });
            } else {
                this.setState({
                    ResultCO2: ('Classe A : ' + valueGr + ' g/CO2/km'), etiquette: backImgA, x: 45, ratioHeigth: 0
                });
            }
        });
        this.setState({statePrevisualize: true});
    }

    handleClickWebandPrint(event) {

        if (event.target.name === "web") {
            this.setState({activeWeb: true, activePrint: false})
        } else {
            this.setState({activeWeb: false, activePrint: true})
        }
        this.setState({stateDimension: true})

    }

    handleDoubleClick(event){

        this.setState({defaultFormat: false})


    }

    handleBlur(event) {


        const target = event.target;
        const value = target.value;
        // console.log(target.value)
        const name = target.name;
        this.setState({[name]: value}, () => {
            if ((this.state.DimmensionH !== "0") && (this.state.DimmensionL !== "0")) {
                this.setState({stateGrammage: true})
                setTimeout(this.focus, 3)
            }else{
                setTimeout(this.focusDim, 3)
            }

        });
    }

    handleClose = () => {
        this.setState(prev => ({modalIsOpen: !prev.modalIsOpen}));
    };

    render() {
        return (<Container id="container" className="p-3">
            <Container>
                <div id="header">
                    <Alert className={"alert-title-grammage"}>
                        {/*<img id={"fleche_header_top"}alt={"fleche_header__top"} /><br/>*/}
                        <Container>
                            <Row>
                                <Col md={9} id={"headerFleche"}>
                                    <p id={"titletext"}>LOMGENERATOR</p>
                                    <img id={"fleche_header_top"} src={fleche_header_top} alt={"fleche_header"}/><br/>
                                    <p id={"subtitletext"}>Générer vos étiquettes Co2</p>
                                    <img id={"fleche_header_bottom"} src={fleche_header_bottom}
                                         alt={"fleche_header_bottom"}/>
                                </Col>
                                <Col md={3} id={"headerLogo"}>
                                    <img id={"logo_bpm"} src={logo_bpm} alt={"logo"}/>
                                </Col>
                            </Row>
                        </Container>
                    </Alert>
                </div>
            </Container>
            <Container>
                <div id={"body_div"}>
                    <Row>
                        <Col sm={4}><ListGroup as="ol">
                            <ListGroup.Item>
                                <div className="ms-sm-1 me-auto">
                                    <div className="fw-bold"><Badge pill bg="success">
                                        1
                                    </Badge> Support
                                    </div>
                                    {this.state.activeWeb ? "Web" : "Print"}
                                </div>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <div className="ms-sm-1 me-auto">
                                    <div className="fw-bold"><Badge pill bg="success">
                                        2
                                    </Badge> Dimension
                                    </div>
                                    {this.state.DimmensionL} X {this.state.DimmensionH} {this.state.activeWeb ? "px" : "mm"}
                                </div>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <div className="fw-bold"><Badge pill bg="success">
                                    3
                                </Badge> Grammage
                                </div>
                                {this.state.ResultCO2}

                            </ListGroup.Item>
                        </ListGroup>
                            <br/>
                            <ButtonGroup aria-label="Basic example">
                                {this.state.statePrevisualize &&

                                    <Button id="btn-prev" variant="previsualize" onClick={this.handleVisu}>
                                        Prévisualiser
                                    </Button>}
                                {this.state.activeWeb && this.state.statePrevisualize &&
                                    <Button id="btn-editor" variant="editor" onClick={this.toggleTicketModal}>{}
                                        Editeur
                                    </Button>}
                                {this.state.statePrevisualize &&
                                    <button id="btn-download" variant="download" className={"btn-download"}>
                                        <a id="link-download" onClick="donwload()">
                                            Télécharger
                                        </a>
                                    </button>}
                                <Button id="btn-annul" className={"btn-annule"} variant="secondary"
                                        onClick={this.cancelForm}>
                                    Annuler
                                </Button>
                            </ButtonGroup>
                        </Col>
                        <Col sm={8}>
                            <Form.Group className="mb-3" controlId="formGrammage">
                                <ButtonGroup aria-label="Basic example">
                                    <Button variant={this.state.activeWeb ? "selected" : "print"}
                                            value={this.state.value}
                                            name="web"
                                            onClick={this.handleClickWebandPrint} disabled={this.state.read}>Web
                                    </Button>
                                    <Button className={"btn-print"}
                                            variant={this.state.activePrint ? "selected" : "print"}
                                            value={this.state.value}
                                            name="print"
                                            onClick={this.handleClickWebandPrint} disabled={this.state.read}>
                                        Print
                                    </Button>
                                </ButtonGroup>
                            </Form.Group>
                            {this.state.stateDimension && <Form.Group className="mb-3" controlId="formDimension">
                                <Form.Label>Dimension</Form.Label>
                                <div>
                                    {this.state.activeWeb &&
                                    <DropdownButton id="dropdown-basic-button" title="Dimension par défaut">
                                        <Button className={"btn-defaultDimmensionInstaCarre"}
                                                name="defaultDimmensionInstaCarre"
                                                variant={'outline-info'}
                                                value={"instgramCarre"}
                                                onClick={this.handleSelectDefaultFormat}>Carré
                                                </Button>
                                        <Button className={"btn-defaultDimmensionInstaVertical"}
                                                name="defaultDimmensionInstaVertical"
                                                variant={'outline-info'}
                                                value={"instgramVertical"}
                                                onClick={this.handleSelectDefaultFormat}>Vertical</Button>
                                        <Button className={"btn-defaultDimmensionStory"}
                                                name="defaultDimmensionStory"
                                                variant={'outline-info'}
                                                value={"story"}
                                                onClick={this.handleSelectDefaultFormat}>Story</Button>
                                    </DropdownButton>
                                }
                                    {this.state.activePrint &&
                                        <DropdownButton id="dropdown-basic-button" title="Dimension par défaut">
                                            <Button className={"btn btn-default btn-lg btn-defaultDimmensionA3"}
                                                    name="defaultDimmensionA3"
                                                    variant={'md outline-info'}
                                                    value={"A3"}
                                                    onClick={this.handleSelectDefaultFormat}>A3</Button>
                                            <Button className={"btn btn-default btn-lg btn-defaultDimmensionA4"}
                                                    name="defaultDimmensionA4"
                                                    variant={'outline-info'}
                                                    value={"A4"}
                                                    onClick={this.handleSelectDefaultFormat}>A4</Button>
                                            <Button className={"btn btn-default btn-lg btn-defaultDimmensionA5"}
                                                    name="defaultDimmensionA5"
                                                    variant={'outline-info'}
                                                    value={"A5"}
                                                    onClick={this.handleSelectDefaultFormat}>A5</Button>
                                        </DropdownButton>
                                    }
                                </div>
                                <br/>
                                {!this.state.defaultFormat &&
                                <Form.Control id="input-L" type="text"
                                              placeholder={this.state.activeWeb ? " L (px)" : " L (mm) "}
                                              value={this.state.value}
                                              onBlur={this.handleBlur}
                                              name="DimmensionL" readOnly={this.state.read}
                                />}
                                {this.state.defaultFormat &&
                                    <Form.Control id="input-L-formatdefault" type="text"
                                                  placeholder={this.state.activeWeb ? " L (px)" : " L (mm) "}
                                                  value={this.state.DimmensionL}
                                                  name="DimmensionL" readOnly={this.state.read}
                                                  onClick={this.handleDoubleClick}
                                    />

                                }
                                {!this.state.defaultFormat &&
                                <Form.Control id="input-H" type="text"
                                              placeholder={this.state.activeWeb ? " H (px)" : " H (mm)"}
                                              value={this.state.value}
                                              onBlur={this.handleBlur}
                                              ref={this.textInputDim}
                                              name="DimmensionH" readOnly={this.state.read}/>}
                                {this.state.defaultFormat &&
                                    <Form.Control id="input-H-formatdefault" type="text"
                                                  placeholder={this.state.activeWeb ? " H (px)" : " H (mm)"}
                                                  value={this.state.DimmensionH}
                                                  onClick={this.handleDoubleClick}
                                                  name="DimmensionH" readOnly={this.state.read}/>

                                }
                            </Form.Group>}
                            {this.state.stateGrammage && <Form.Group className="mb-3" controlId="formGrammage">
                                <Form.Label>Grammage</Form.Label>
                                <Form.Control id="input-grammage"   name="Gram" type="text" placeholder="g/CO2/km"
                                              value={this.state.GramValue}
                                              onChange={this.handleStat}
                                              readOnly={this.state.read}
                                              ref={this.textInput}
                                />
                                <RangeSlider
                                    name="Gram" min={0} max={300} defaultValue={0} value={this.state.GramValue}
                                    onChange={this.handleStat}
                                    disabled={this.state.read}/>
                            </Form.Group>}
                        </Col>
                    </Row>
                    <ButtonGroup aria-label="Basic example" size="sm">
                        {this.state.statePrevisualize &&
                            <Button id="btn-prev-phone" variant="previsualize" onClick={this.handleVisu}>
                                Prévisualiser
                            </Button>}
                        {this.state.statePrevisualize &&
                            <Button id="btn-editor-phone" variant="editor" onClick={this.toggleTicketModal}>
                                Editeur
                            </Button>}
                        {this.state.statePrevisualize && <Button id="btn-download-phone" variant="download">
                            <a id="link-download-phone" onClick="donwload()">
                                Télécharger
                            </a>
                        </Button>}
                        <Button id="btn-annul-phone" className={"btn-annule"} variant="secondary"
                                onClick={this.cancelForm}>
                            Annuler
                        </Button>
                    </ButtonGroup>
                    <br/>
                    <Row>
                        <Col md={4}>
                            {this.state.showStore && <div className="App" id={"imgVisu"}>
                                <Canvas ref={"canvas"} name={"canvas"} id={"canvas"}
                                        text={this.state.GramValue + " gCO2/km"}
                                        alt={this.state.x} src={this.state.etiquette}
                                        width={this.state.DimmensionL}
                                        heigth={this.state.DimmensionH} type={this.state.ratioHeigth}
                                        placeholder={this.state.activeWeb}/>

                                <Canvasprint ref={"canvasPrint"} name={"canvasPrint"} id={"canvasPrint"}
                                             text={this.state.GramValue + " gCO2/km"}
                                             alt={this.state.x} src={this.state.etiquette}
                                             width={Math.round(this.state.dimReal)}
                                             heigth={Math.round(this.state.dimReal)}
                                             type={this.state.ratioHeigth}
                                             placeholder={this.state.activeWeb}/>
                            </div>}
                        </Col>
                    </Row>
                </div>
            </Container>

            <div id={"footer"}>
                <Alert className={"alert-footer-grammage"}>
                    <Container>
                        <Row>
                            <Col md={12}>
                                Les étiquettes sont obligatoires dans les cas suivants :
                                <ul>
                                    <li> Publicité en faveur d’une voiture particulière neuve à la vente ou à la
                                        location longue
                                        durée
                                    </li>
                                    <li> Publicité sur des essais de véhicules</li>
                                    <li> Publicité d’un SAV ou d’une garantie, en faveur d’un véhicule</li>
                                    <li> Publicité d’une estimation d’un véhicule en lien avec un véhicule</li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </Alert>

            </div>
            {this.state.modalIsOpen && <Modal
                isOpen={this.state.modalIsOpen}
                style={this.state.customStyles}
                id={"modalPreview"}

            >
                <HomePage ref={"pageClipBoard"} width={this.state.DimmensionL}
                          heigth={this.state.DimmensionH} state={this.state.isOpen}
                          handleClose={this.handleClose}/>
            </Modal>}

        </Container>);
    }
}

export default GrammageForm
