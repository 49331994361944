import React from "react";

class Canvas extends React.Component {

    componentDidMount() {

        const canvas = this.refs.canvas;
        const ctx = canvas.getContext("2d");
        const img = this.refs.image;

        img.onload = () => {
            ctx.drawImage(img, 0, 0, 300, 300);
            ctx.font = "bold 35px Calibri";
            ctx.fillStyle = "white";
            ctx.strokeStyle = "black";
            ctx.fillText(this.props.text, 65, this.props.alt); // THIS IS THE PLACE TEXT IS EMBEDDED INTO THE PICTURE
            ctx.strokeText(this.props.text, 65, this.props.alt);
        };
    }

    render() {
        return (<div>
            <canvas ref="canvas" width={300} height={300}/>
            <img
                ref="image"
                alt="Stackoverflow56203352"
                src={this.props.src}
                width={50}
                height={50}
                className="hidden"
                style={{display: 'none'}}
            />
        </div>);
    }
}

export default Canvas;
